import React, { useEffect, useState, useContext } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import PageSpinner from "../PageSpinner";
import CustomTable from "../CustomTable";
import PageSubtitle from "../PageSubtitle";
import RequestCanceller from "../RequestCanceller";
import { GlobalStateContext } from "../../state";
import { loadRequests } from "../../utilities/requests";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import "./Scheduled.css";

dayjs.extend(customParseFormat);

const groupFormat = "DD-MM-YYYY";

const Scheduled = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [requests, setRequests] = useState({});
  const [stagedFilterText, setStagedFilterText] = useState("");
  const [filterText, setFilterText] = useState("");

  const [state, { dispatch }] = useContext(GlobalStateContext);

  const postCancel = () => {
    loadRequests(dispatch, "scheduled");
  };

  useEffect(() => {
    document.title = `${t("scheduled")} | XL EDS`;
  });

  const groupRequests = (loadedRequests) => {
    setRequests(
      loadedRequests.reduce((grouped, request) => {
        const day = dayjs(request.Job.pickup_at).format(groupFormat);
        return {
          ...grouped,
          [day]: [...(grouped[day] || []), request],
        };
      }, {})
    );
  };

  useEffect(() => {
    loadRequests(dispatch, "scheduled", filterText);
  }, [dispatch, filterText]);

  useEffect(() => {
    if (state.scheduled) groupRequests(state.scheduled);
  }, [state.scheduled]);

  const open = (id) => () => {
    history.push(`/jobs/${id}`);
  };

  const submitFilter = (e) => {
    e.preventDefault();
    setFilterText(stagedFilterText);
  };

  const clearFilter = () => {
    setStagedFilterText("");
    setFilterText("");
  };

  const getPrice = ({ Job: { pricing, transport_type }, PricingInfo }) => {
    if (PricingInfo) return PricingInfo.price;
    return pricing;
  };

  return (
    <Container fluid>
      {!state.scheduled && <PageSpinner></PageSpinner>}
      {state.scheduled && !state.scheduled.length && !filterText.length && (
        <div className="scheduled-empty">
          <img src="no-scheduled.svg" alt="no scheduled" />
          <h3>{t("noScheduledTitle")}</h3>
          <p>{t("noScheduledDescription")}</p>
          <Button variant="info" as={Link} to="/">
            {t("requestDelivery")}
          </Button>
        </div>
      )}
      {state.scheduled &&
        (state.scheduled.length > 0 || filterText.length > 0) && (
          <div>
            <PageSubtitle>
              <h6>
                {state.scheduled.length}{" "}
                {t("delivery", { count: state.scheduled.length })}
              </h6>
            </PageSubtitle>
            <br />
            <Form onSubmit={submitFilter}>
              <Row>
                <Col xs={{ offset: "1", span: "auto" }}>
                  <InputGroup>
                    <Form.Control
                      id="searchInArray"
                      type="text"
                      placeholder={t("searchInArray")}
                      value={stagedFilterText}
                      onChange={(e) => {
                        setStagedFilterText(e.target.value);
                      }}
                    />
                    <InputGroup.Append className="history-clearicon">
                      <InputGroup.Text
                        onClick={clearFilter}
                        disabled={!stagedFilterText.length}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
                <Col>
                  <Button variant="info" onClick={submitFilter}>
                    {" "}
                    {t("filter")}{" "}
                  </Button>
                </Col>
              </Row>
            </Form>
            <br />
            <Row>
              <Col xs={{ span: 10, offset: 1 }}>
                <CustomTable>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>{t("date")}</th>
                      <th>
                        {t("firstName")} et {t("lastName")}
                      </th>
                      <th>{t("pickup")}</th>
                      <th>
                        {t("firstName")} et {t("lastName")}
                      </th>
                      <th>{t("dropoff")}</th>
                      {state.user.UserConfig.ShowPricing && (
                        <th>{t("price")}</th>
                      )}
                      <th></th>
                    </tr>
                  </thead>
                  {Object.keys(requests)
                    .sort((a, b) =>
                      dayjs(a, groupFormat).isBefore(dayjs(b, groupFormat))
                        ? -1
                        : 1
                    )
                    .map((day) => (
                      <tbody key={day}>
                        {requests[day].map((request, index) => (
                          <tr
                            key={index}
                            className="clickable"
                            onClick={open(request.Job.id)}
                          >
                            <td>#{request.Job.id}</td>
                            <td>
                              {dayjs(request.Job.pickup_at).format(
                                "DD/MM/YYYY HH:mm"
                              )}
                            </td>
                            <td>
                              {
                                request.Job?.deliveries[0].pickup.contact
                                  .firstname
                              }{" "}
                              {
                                request.Job?.deliveries[0].pickup.contact
                                  .lastname
                              }
                            </td>
                            <td>
                              {
                                request.Job.deliveries[0].pickup.address
                                  .formatted_address
                              }
                            </td>
                            <td>
                              {
                                request.Job?.deliveries[0].pickup.contact
                                  .firstname
                              }{" "}
                              {
                                request.Job?.deliveries[0].pickup.contact
                                  .lastname
                              }
                            </td>

                            <td>
                              {
                                request.Job.deliveries[0].dropoff.address
                                  .formatted_address
                              }
                              &nbsp;
                              {request.Job.deliveries.length > 1 && (
                                <span>
                                  {t("otherDeliveriesCount", {
                                    count: request.Job.deliveries.length - 1,
                                  })}
                                </span>
                              )}
                            </td>
                            {state.user.UserConfig.ShowPricing && (
                              <td>€{getPrice(request).price_tax_included}</td>
                            )}
                            <td>
                              <RequestCanceller
                                entityId={request.Job.id}
                                entity="jobs"
                                destinations={request.Job.deliveries.map(
                                  (d) => d.dropoff.address.formatted_address
                                )}
                                postCancel={postCancel}
                              >
                                <FontAwesomeIcon
                                  icon={faTimes}
                                  className="scheduled-cancelicon"
                                />
                              </RequestCanceller>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    ))}
                </CustomTable>
              </Col>
            </Row>
          </div>
        )}
    </Container>
  );
};

Scheduled.defaultProps = {};

export default Scheduled;
