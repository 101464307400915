import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faBolt, faClock } from '@fortawesome/free-solid-svg-icons'
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat)

const dates = new Array(30).fill(dayjs()).map((m, i) => dayjs(m).add(i, "day"))

const getSlots = (startTime, endTime, interval) => {
    const next = dayjs(startTime).add(interval, "minute");
    const shouldInclude = startTime.isAfter(dayjs());
    const base = shouldInclude ? [ startTime ] : [];
    if (next.isAfter(endTime)) return [...base];
    return [...base, ...getSlots(next, endTime, interval)]
}

const getSlotsOfDay = date => {
  const startTime = dayjs(date).hour(7).minute(0);
  const endTime = dayjs(date).hour(22).minute(30);
  const times = getSlots(startTime, endTime, 15);
  return times;
}

export const OrderDate = (props) => {

    const { t } = useTranslation();

    const [date, setDate] = useState(dates[0]);
    const [slots, setSlots] = useState(getSlotsOfDay(date));
    const [isLaterChecked, setIsLaterChecked] = useState(false);

    const setQuery = value => {
      const settedValue = value;
      props.setter(settedValue)
    }

    const updateDate = value => {
      setDate(value);
      const times = getSlotsOfDay(value);
      setSlots(times)
      setQuery(dayjs(times[0]).format())
    };

    const updateSlot = e => { setQuery(e.target.value) }

    const setLater = () => {
      setQuery(dayjs(slots[0]).format())
      setIsLaterChecked(true)
    }

    const setNow = () => {
      props.setter(null)
      setIsLaterChecked(false)
    }

    return (
        <Fragment>
            <Container className="App-default-component" id="orderDateAndTime">
                <Form>
                    <Row>
                        <Col xs={1}>
                            <FontAwesomeIcon icon={faClock} size="2x" color="#6e71e9" />
                        </Col>
                        <Col>
                            <h4>{t("orderDateTitle")}</h4>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col xs={1}>
                            <Form.Check
                                id={1}
                                custom
                                label=""
                                type="radio"
                                checked={!isLaterChecked}
                                onChange={setNow}
                            />
                        </Col>
                        <Col xs={2.5}>
                            <label> <FontAwesomeIcon icon={faBolt} /> {t("now")}</label>
                        </Col>
                        <Col xs={1}>
                            <Form.Check
                                id={2}
                                custom
                                label=""
                                type="radio"
                                checked={isLaterChecked}
                                onChange={setLater}
                            />
                        </Col>
                        <Col xs={2.5}>
                            <label> <FontAwesomeIcon icon={faCalendarAlt} /> {t("later")}</label>
                        </Col>
                    </Row>

                    {isLaterChecked && <Row>
                        <Col xs={6}>
                            {t("date")}
                            <br />
                            <Form.Control as="select" onChange={(event) => updateDate(dayjs(event.target.value, "DD MMMM YY"))}>
                                {dates.map((dateOption, index) =>
                                    <option key={index} value={dateOption.format("DD MMMM YY")}>{dateOption.format("DD MMMM")}</option>
                                )}
                            </Form.Control>
                        </Col>
                        <Col xs={6}>
                            {t("timeSlot")}
                            <br />
                            <Form.Control as="select" onChange={updateSlot} value={props.value || ""}>
                                {slots.map((element, index) =>
                                    <option key={index} value={dayjs(element).format()}>
                                        {`${element.format("HH:mm")} - ${dayjs(element).add(120, "minutes").format("HH:mm")}`}
                                    </option>
                                )}
                            </Form.Control>
                        </Col>
                    </Row>}
                </Form>
            </Container>
        </Fragment>
    );
};

export default OrderDate;
