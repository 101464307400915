import React, { Fragment, useState, useRef, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faSpinner, faChevronCircleUp, faChevronCircleDown, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { GlobalStateContext } from '../../state';
import AddressInput from "../AddressInput";
import { validateRequest, getXLVehicleType } from '../../utilities/requests';
import InputMask from 'react-input-mask';

import "./Address.css"

const phoneMask = "99 99 99 99 99";


const validatePhone = value => value.length === 0 || value.length === 10;
const defaultContact = {
  firstname: "",
  lastname: "",
  phone: "",
  email: ""
}

const initQuery = query => ({
  ...query,
  contact: {
    ...query.contact,
    phone: query.contact.phone.replace("+33", "0"),
  }
})

export const Address = (props) => {

  const defaultQuery = {
    address: "",
    comment: "",
    contact: defaultContact,
    ...(props.showDetails ? { client_reference: "", package_description: "" } : {})
  }

  const { t } = useTranslation();
  const [state] = useContext(GlobalStateContext);
  const [addressError, setAddressError] = useState("");
  const [query, setQuery] = useState(initQuery(props.query || defaultQuery));
  const [validation, setValidation] = useState({
    phone: validatePhone(query.contact.phone),
    email: true,
    address: false
  });
  const [packageDescription, setPackageDescription] = useState("");
  const [alreadySaved, setAlreadySaved] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const form = useRef(null);

  const iconValidation = query.address?.length > 0 ? "valid" : "empty";


  useEffect(() => {
    setPackageDescription(query.package_description || "");
  }, [query])


  const setQueryAndProps = (newQuery) => {
    setQuery(newQuery);
    props.setter(newQuery);
  }

  const updateQuery = (field, value) => {
    const newQuery = { ...query, [field]: value }
    recheckValidation(newQuery, true);
  }

  const updateContact = (field, value) => {
    updateQuery("contact", { ...query.contact, [field]: value })
  }

  const getContactValidation = (phone, email) => {
    return {
      phone: validatePhone(phone),
      email: !email.length || /^[\w-\.\+]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email)
    };
  }

  const setAddressValidation = (addressIsValid, error, newQuery, stagedValidation) => {
    const newValidation = { ...stagedValidation, address: addressIsValid };
    setValidation(newValidation);
    setAddressError(error);
    const isValid = Object.values(newValidation).every(Boolean)
    setQueryAndProps({ ...newQuery, isValid })
  }

  const checkAddress = (value, newValidation) => {
    const { address = "", contact: { phone = "" } = {} } = value;
    if (!address.length) {
      setAddressValidation(false, "", value, newValidation)
    } 
    else {
      setIsValidating(true)
      validateRequest({ address, phone, type: props.type }).then(({success, error}) => {
        if(success) setAddressValidation(true, "", value, newValidation);
        else setAddressValidation(false, error, value, newValidation)
      }).finally(() => { setIsValidating(false) })
    }
  }

  const recheckValidation = (newQuery, skipAddress) => {
    const { phone, email } = newQuery.contact;
    const contactValidation = getContactValidation(phone, email);
    setValidation({ ...validation, ...contactValidation });
    const isValid = Object.values(contactValidation).every(Boolean)
    const updatedPackageDescription = newQuery.package_description || "";
    setPackageDescription(updatedPackageDescription);
    const newQueryWithValid = { ...newQuery, isValid: isValid && updatedPackageDescription.length <= 1000 && !!!addressError }
    setQuery(newQueryWithValid);
    const shouldCheckAddress = isValid && !skipAddress && !props.noCheck;
    if(shouldCheckAddress) checkAddress(newQueryWithValid, contactValidation);
    else setQueryAndProps(newQueryWithValid);
  }
  
  const onAddressChange = value => {
    const newQuery = {
      ...query,
      ...value,
      contact: {
        ...query.contact,
        ...value.contact
      }
    }
    const isSaved = state.addresses.some(a => a.address === newQuery.address)
    setAlreadySaved(isSaved)
    if (isSaved && !props.hideSaveOption) newQuery.save = false;
    recheckValidation(newQuery);
  }

  const updatePhone = e => {
    const rawValue = e.target.value;
    const spaceless = rawValue.replace(/[\s_]/g, "");
    const newQuery = { ...query, contact: { ...query.contact, phone: spaceless }};
    recheckValidation(newQuery);
  }

  const beforeMaskedValueChange = (newState, oldState, userInput) => {
    var { value } = newState;
    var selection = newState.selection;

    if (value !== "" && value[0] !== "0") {
      value = "0" + value 
      selection = { start: value.length, end: value.length };
    }

    if (value.length > 14) {
      value = value.slice(0, 14)
    }

    return {
      value,
      selection
    };
  }

  const updateEmail = e => {
    const value = e.target.value;
    const newContact = { ...query.contact, email: value };
    const newQuery = { ...query, contact: newContact};
    recheckValidation(newQuery, true);
  }

  const setSave = value => {
    const newQuery = { ...query, save: value };
    setQueryAndProps(newQuery);
  }

  return (
    <Fragment>
      <Container id={props.id}>
        <Form ref={form} noValidate className="address-form" onSubmit={(e) => e.preventDefault()}>
          {!props.noTitle && <Row>
            <Col xs={1}>
              <div className="address-iconcontainer" status={iconValidation}>
                <img src={props.iconSrc} width="32" alt="icon for address component" />
              </div>
            </Col>
            <Col>
              <h4>
                { !props.collapsed && props.address}
                { props.collapsable && ` ${props.index + 1}: `}
                { props.collapsed && query.address}
              </h4>
            </Col>
            { props.collapsable && query.address.length > 0 &&
              <Col xs="auto">
                { !props.collapsed && <FontAwesomeIcon
                  icon={faChevronCircleUp}
                  onClick={props.toggleCollapse(true)}
                />}
                { props.collapsed && <FontAwesomeIcon
                  icon={faChevronCircleDown}
                  onClick={props.toggleCollapse(false)}
                />}
              </Col>
            }
            { Boolean(props.removable) && <Col xs="auto">
              <FontAwesomeIcon icon={faTimesCircle} onClick={props.onRemove} />
            </Col> }
          </Row>}
          { !props.collapsed && <div>
            <br />
            { !props.hideAddress && <Row>
              <Col>
                <AddressInput
                  onChange={onAddressChange}
                  id={`${props.id}-address`}
                  hideSaved={props.hideSavedAddresses}
                  value={query}
                  type={props.type}
                />
              </Col>
            </Row> } 
            {!query.address.length && <Row>
              <Col className="address-illustration-container"><img src={props.illustration} alt="illustration for address component" /></Col>
            </Row>}
            {query.address.length > 0 && <div>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>{t('firstName')}</Form.Label>
                    <Form.Control
                      onChange={e => { updateContact("firstname", e.target.value) }}
                      type="text"
                      value={query.contact.firstname}
                      placeholder={t('firstName')}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>{t('lastName')}</Form.Label>
                    <Form.Control
                      onChange={e => { updateContact("lastname", e.target.value) }}
                      value={query.contact.lastname}
                      type="text"
                      placeholder={t('lastName')}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>{t('phone')}</Form.Label>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text><img src="/france-flag.png" className="address-phone-flag" alt="france flag" /></InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        as={InputMask}
                        value={query.contact.phone}
                        onChange={updatePhone}
                        beforeMaskedValueChange={beforeMaskedValueChange}
                        mask={phoneMask}
                        placeholder="0_ __ __ __ __"
                        maskChar = {null}
                        required
                        type="tel" />
                    </InputGroup>
                    {!validation.phone && <Form.Text className="address-invalidmsg"> 
                      {t("missingPhoneLength", {
                        number: 10 - query.contact.phone.length
                      })}
                    </Form.Text>}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>{t('email')}</Form.Label>
                    <Form.Control
                      onChange={updateEmail}
                      value={query.contact.email}
                      type="email"
                      placeholder={t('email')}
                    />
                  </Form.Group>
                  {!validation.email && <Form.Text className="address-invalidmsg"> {t("invalidEmail")}</Form.Text>}
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>{t('courierDetails')}</Form.Label>
                    <Form.Control
                      onChange={e => { updateQuery("comment", e.target.value) }}
                      value={query.comment}
                      type="text"
                      placeholder={t('detailsPlaceholder')}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {props.showDetails && <div>
                <Row><Col><br /><h5>{t("orderDetails")}</h5><br /></Col></Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>{t('orderId')}</Form.Label>
                      <Form.Control
                        onChange={e => { updateQuery("client_reference", e.target.value) }}
                        value={query.client_reference}
                        type="text"
                        placeholder={t('orderId')}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                  <Form.Group>
                            <Form.Label>{t("orderInfos")}</Form.Label>
                            <Form.Control
                              onChange={(e) => {
                                updateQuery(
                                  "package_description",
                                  e.target.value
                                );
                              }}
                              value={query.package_description}
                              type="text"
                              placeholder={t("orderInfos")}
                            />
                            {!!query.package_description && <Form.Text
                              className={
                                packageDescription.length > 1000 &&
                                "address-invalidmsg"
                              }
                            >
                              {packageDescription.length <= 1000
                                ? t("packageDescriptionAvailableCharacters", {
                                    nbrAvailableCharacters:
                                      1000 - packageDescription.length,
                                  })
                                : t("packageDescriptionLimitExceeded")}
                            </Form.Text>}
                          </Form.Group>
                  </Col>
                </Row>
              </div>}
              {isValidating && <div>
                <FontAwesomeIcon icon={faSpinner} pulse />
                <span>{t("validatingAddress")}</span>
              </div>}
              {!props.hideSaveOption && !isValidating && <div>
                {!alreadySaved && validation.address && <Row>
                  <Form.Check
                    type="switch"
                    id={`${props.id}-switch`}
                    label={t("saveAddressLabel")}
                    checked={query.save}
                    onChange={e => { setSave(e.target.checked) }}
                  />
                </Row>}
                {alreadySaved && <Row>
                  <Col>
                    <FontAwesomeIcon icon={faExclamationCircle} /> {t("addressAlreadySaved")}
                  </Col>
                </Row>}
              </div>}
              {!alreadySaved && !validation.address && !isValidating && <Row>
                <span className="address-invalidmsg">{t(addressError)}</span>
              </Row>}
            </div>}
          </div> }
        </Form>
      </Container>
    </Fragment >
  );
};

Address.propTypes = {
  autoCompleteRef: PropTypes.object,
  query: PropTypes.object,
  changefunc: PropTypes.func,
  iconSrc: PropTypes.string,
  type: PropTypes.oneOf(["picking", "delivering"])
};

Address.defaultProps = {
  type: "picking"
}

export default Address;
